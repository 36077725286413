
.page-content .ThreeBallSixesSummary form {
  padding: 20px 10px 10px;
}

.ThreeBallSixesSummary .holes-container {
  padding: 0;
  width: 100%;
  max-width: 840px;
  margin: 0 auto;
}
.ThreeBallSixesSummary .holes-container form {
  max-width: none;
  margin: 0 auto 40px auto;
}

.ThreeBallSixesSummary .hole-desc-col {
  width: 20% !important;
  min-width: 95px;
  padding-top: 15px;
}

.ThreeBallSixesSummary .hole-pts-col,
.ThreeBallSixesSummary .hole-score-col {
  width: 80%;
  text-align: right;
  vertical-align: middle;
}

.ThreeBallSixesSummary .hole-score-col input {
  text-align: center;
}

.ThreeBallSixesSummary .hole-desc {
  margin-bottom: 5px;
}

.ThreeBallSixesSummary .holes-table .form-group {
  margin: 0;
}

.ThreeBallSixesSummary .holes-table .error-message {
  display: none;
}

.ThreeBallSixesSummary .holes-table .has-error .error-message {
  margin-top: 7px;
  display: block;
}

.ThreeBallSixesSummary .holes-table .total-row td {
  font-weight: bold;
}

.ThreeBallSixesSummary .score-indicator {
  width: 34px;
  height: 34px;
  margin: 0 auto;
  display: inline-block;
  text-align: center;
}

.ThreeBallSixesSummary .eagle,
.ThreeBallSixesSummary .albatross,
.ThreeBallSixesSummary .birdie,
.ThreeBallSixesSummary .bogey,
.ThreeBallSixesSummary .double-bogey {
  border: 2px solid #31708f;
  padding-left: 0px;
}

.ThreeBallSixesSummary .albatross {
  background-color: #31708f;
  color: #fff;
}

.ThreeBallSixesSummary .bogey,
.ThreeBallSixesSummary .double-bogey {
  border: 2px solid #a94442;
}

.ThreeBallSixesSummary .albatross,
.ThreeBallSixesSummary .eagle,
.ThreeBallSixesSummary .birdie {
  border-radius: 54px;
}

.ThreeBallSixesSummary .par,
.ThreeBallSixesSummary .albatross,
.ThreeBallSixesSummary .birdie,
.ThreeBallSixesSummary .bogey {
  padding-top: 4px;
}

.ThreeBallSixesSummary .eagle,
.ThreeBallSixesSummary .double-bogey {
  border-style: double;
  border-width: 6px;
  width: 34px;
}

.ThreeBallSixesSummary .mini-table .name-td {
  width: 40%;
  min-width: 120px;
  text-align: left;
}

.ThreeBallSixesSummary .mini-table tr {
  height: 45px;
}

.ThreeBallSixesSummary .mini-table .scores-td {
  min-width: 20px;
  width: 20%;
  text-align: center;
}