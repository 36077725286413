.ForgottenPassword {
  padding: 30px;
}

@media all and (min-width: 480px) {
  .ForgottenPassword {
    padding: 30px 0;
  }

  .ForgottenPassword form {
    margin: 0 auto;
    max-width: 320px;
    padding: 0;
    box-shadow: none;
    border-top: none;
    background-color: transparent;
    box-sizing: unset;
  }
}

.ForgottenPassword form span.help-block {
  font-size: 14px;
  padding-bottom: 10px;
  color: #999;
}

@media all and (max-width: 1023px) {
  .ForgottenPassword form .password-incomplete {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.ForgottenPassword .error-message {
  display: none;
}

.ForgottenPassword .has-error .error-message {
  margin-top: 7px;
  display: block;
}
