.FileInput .file-upload-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.FileInput .file-upload-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}

.FileInput .loading-file-upload.file-upload-wrapper input[type="file"] {
  display: none;
}

.FileInput label {
  display: block;
}

.FileInput .btn .fas {
  margin-right: 10px;
}

@media (max-width: 767px) {
  .FileInput .file-upload-wrapper {
    width: 100%;
  }
  .FileInput .btn {
    width: 100%;
  }
}

.FileInput .glyphicon {
  margin-right: 7px;
  top: 2px;
}
.FileInput .spinning.glyphicon {
  animation: spin 1s infinite linear;
}
@keyframes spin {
  from {
    transform: scale(1) rotate(0deg);
  }
  to {
    transform: scale(1) rotate(360deg);
  }
}
