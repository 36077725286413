.ConfirmAccount {
  padding: 30px;
}

@media all and (min-width: 480px) {
  .ConfirmAccount {
    padding: 30px 0;
  }

  .ConfirmAccount form {
    margin: 0 auto;
    max-width: 320px;
    padding: 0;
    box-shadow: none;
    border-top: none;
    background-color: transparent;
    box-sizing: unset;
  }
}

.ConfirmAccount form span.help-block {
  font-size: 14px;
  padding-bottom: 10px;
  color: #999;
}

.ConfirmAccount .error-message {
  display: none;
}

.ConfirmAccount .has-error .error-message {
  margin-top: 7px;
  display: block;
}
