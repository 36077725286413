.GolferScores .holes-container {
  padding: 0;
  width: 100%;
  max-width: 840px;
  margin: 0 auto;
}
.GolferScores .holes-container form {
  max-width: none;
  margin: 0 auto 40px auto;
}
.GolferScores .hole-pts-col {
  width: 50px;
  text-align: center;
  vertical-align: middle;
  max-width: 50px;
}

.GolferScores .hole-desc-col,
.GolferScores .hole-score-col {
  width: 40%;
  text-align: center;
  vertical-align: middle;
}

.GolferScores .static .hole-desc-col {
  width: 40%;
}

.GolferScores .static .hole-pts-col,
.GolferScores .static .hole-score-col {
  width: 30%;
  max-width: 50px;
}

.GolferScores .hole-score-col input {
  text-align: center;
}

.GolferScores .hole-desc {
  margin-bottom: 5px;
}

.GolferScores .holes-table .form-group {
  margin: 0;
}

.GolferScores .holes-table .error-message {
  display: none;
}

.GolferScores .holes-table .has-error .error-message {
  margin-top: 7px;
  display: block;
}

.GolferScores .holes-table .total-row td {
  font-weight: bold;
}

.GolferScores .score-indicator {
  width: 34px;
  height: 34px;
  margin: 0 auto;
}

.GolferScores .eagle,
.GolferScores .albatross,
.GolferScores .birdie,
.GolferScores .bogey,
.GolferScores .double-bogey {
  border: 2px solid #31708f;
  padding-left: 0px;
}

.GolferScores .albatross {
  background-color: #31708f;
  color: #fff;
}

.GolferScores .bogey,
.GolferScores .double-bogey {
  border: 2px solid #a94442;
}

.GolferScores .albatross,
.GolferScores .eagle,
.GolferScores .birdie {
  border-radius: 54px;
}

.GolferScores .par,
.GolferScores .albatross,
.GolferScores .birdie,
.GolferScores .bogey {
  padding-top: 4px;
}

.GolferScores .eagle,
.GolferScores .double-bogey {
  border-style: double;
  border-width: 6px;
  width: 34px;
}
