.Signup {
  padding: 30px;
}

.Signup .back-to-step-one {
  padding: 0 0 10px 0;
  font-size: 16px;
  display: none;
}

@media (max-width: 370px) {
  .Signup .back-to-step-one {
    font-size: 13px;
  }
}

@media all and (min-width: 480px) {
  .Signup {
    padding: 30px 0;
  }

  .Signup form {
    margin: 0 auto;
    max-width: 320px;
    padding: 0;
    box-shadow: none;
    border-top: none;
    background-color: transparent;
    box-sizing: unset;
  }
}

.Signup form span.help-block {
  font-size: 14px;
  padding-bottom: 10px;
  color: #999;
}

@media all and (max-width: 1023px) {
  .Signup form .password-incomplete {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.Signup .error-message {
  display: none;
}

.Signup .has-error .error-message {
  margin-top: 7px;
  display: block;
}

.Signup .confirmation-code-link {
  text-align: center;
  margin-top: 15px;
}

.confirmation-code-link a:hover {
  cursor: pointer;
}

.Signup .login-link {
  border-top: 1px solid black;
  margin-top: 20px;
  padding-top: 20px;
  text-align: center;
}

.Signup .login-link .btn {
  margin-top: 15px;
}
