.page-content .LoaderButton {
  margin-top: 40px !important;
}
.page-content .btn-group .LoaderButton {
  margin-top: 0 !important;
}
.LoaderButton .glyphicon {
  margin-right: 7px;
  top: 2px;
}
.LoaderButton .spinning.glyphicon {
  animation: spin 1s infinite linear;
}
@keyframes spin {
  from {
    transform: scale(1) rotate(0deg);
  }
  to {
    transform: scale(1) rotate(360deg);
  }
}
