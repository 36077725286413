.ScoresByRoundDetail .round-col {
  width: 50%;
}
.ScoresByRoundDetail .score-col {
  width: 50%;
}
.ScoresByRoundDetail .total {
  font-weight: bold;
}
.ScoresByRoundDetail {
  margin-top: 30px;
}

.ScoresByRoundDetail tbody tr.round-row {
  background-color: #d9edf7;
  font-weight: bold;
}

.ScoresByRoundDetail tbody tr.round-row td:first-child {
  border-right: none;
}

.ScoresByRoundDetail tbody tr.round-row td:last-child {
  border-left: none;
}

.ScoresByRoundDetail .fa-trophy {
  margin-left: 5px;
}
