.GolferRoundsList .name-col {
  width: 50%;
}

.GolferRoundsList .date-col {
  min-width: 86px;
  width: 25%;
}

.GolferRoundsList .points-col {
  min-width: 86px;
  width: 25%;
}
