@media (min-width: 992px) {
  .Home .HandicapChart h4,
  .Home .first-line {
    display: none;
  }
}

.Home .GolferTitle {
  margin-bottom: 5px;
}

.Home .GolferTitle .handicap-title {
  color: #ef476f;
}

@media (max-width: 600px) {
  .Home .GolferTitle .handicap-title-dash {
    display: none;
  }
  .Home .GolferTitle .handicap-title {
    display: block;
    margin-top: 10px;
  }
}

@media (max-width: 991px) {
  .recent-badges {
    margin-top: 30px;
  }
}
