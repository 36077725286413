.FinalScores .final-scores-table .form-group {
  margin-bottom: 0;
}

.FinalScores .stableford-points-name-col {
  width: 33.3%;
}

.FinalScores .stableford-points-col {
  width: 33.3%;
}

.FinalScores .scores {
  vertical-align: middle;
}
