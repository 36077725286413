.CompetitionSummary .name {
  font-weight: bold;
}

.CompetitionSummary .comp-winner {
  margin: 8px 0;
}

.CompetitionSummary .round-complete {
  margin-top: 10px;
  display: block;
}

.CompetitionSummary .comp-summary-grid {
  width: 100%;
  padding: 0;
}

/* .CompetitionSummary .gallery-col {
  border-left: 2px solid #ddd;
} */

.CompetitionSummary .gallery-link {
  margin-bottom: 15px;
}

.CompetitionSummary .round-uncomplete-group {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
}


.CompetitionSummary .round-uncomplete-group-name {
  display: block;
  font-weight: bold;
}