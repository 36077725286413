.Photos .ReactGridGallery {
  width: 100%;
  display: inline-block;
}

.page-content .Photos .LoaderButton.image-delete {
  margin-top: 0px !important;
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .Photos .image-delete {
    width: 100%;
  }
}
