/*
 * Main content
 */

#main {
  padding: 20px 20px 20px 20px;
}

#main .page-header {
  margin-top: 0;
}

.main-app-name {
  margin: 30px auto 0 auto;
  max-width: 320px;
  text-align: center;
  font-size: 3rem;
  font-family: "Kaushan Script", "Helvetica Neue", Helvetica, Arial, cursive;
}

.main-app-name a:hover,
.main-app-name a:focus {
  text-decoration: none !important;
  color: #06af8e;
}

.nav-side-menu {
  overflow: auto;
  position: fixed;
  top: 0px;
  width: 200px;
  height: 100%;
  background-color: #f5f5f5;
  box-shadow: 2px -2px 11px 1px rgb(207, 206, 206);
}
.nav-icon {
  min-width: 25px;
  color: #ddbbca;
}

.nav-menu-item .active .nav-icon {
  color: #fff;
}
.nav-side-menu > .main-app-name {
  margin: 0;
  font-size: 2.5rem;
}
.nav-side-menu .brand {
  line-height: 50px;
  display: block;
  text-align: center;
  padding: 15px;
}
.nav-side-menu .toggle-btn {
  display: none;
}
.nav-side-menu ul,
.nav-side-menu li {
  list-style: none;
  line-height: 35px;
  cursor: pointer;
}

.nav-side-menu ul {
  padding-left: 0;
}

.nav-side-menu li {
  display: block;
}

.nav-side-menu li > a,
.nav-side-menu li > a:focus,
.nav-side-menu li > a:hover {
  display: block;
  padding: 5px 10px 5px 30px;
  text-decoration: none;
}

.nav-side-menu li > a.active,
.nav-side-menu li > a.active:hover,
.nav-side-menu li > a.active:focus {
  color: #fff;
  background-color: #428bca;
}

.nav-side-menu li:hover {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
}

@media (max-width: 400px) {
  .btn-group-lg > .btn,
  .btn-lg {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .nav-side-menu {
    position: relative;
    width: 100%;
    margin-bottom: 10px;
  }
  .nav-side-menu .toggle-btn {
    display: block;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 22px;
    z-index: 10 !important;
    padding: 3px;
    width: 40px;
    text-align: center;
  }

  .brand {
    text-align: left !important;
    font-size: 22px;
    padding-left: 20px 10px;
    line-height: 50px !important;
  }
}
@media (min-width: 767px) {
  .nav-side-menu .menu-list .menu-content {
    display: block;
  }
  #main {
    width: calc(100% - 200px);
    float: right;
    padding: 20px 20px;
  }
}

#main.page-loading .page-content {
  display: none;
}

#main.page-error .page-content {
  display: none;
}

.logo {
  position: relative;
  top: -3px;
  margin-right: -5px;
}

.brand:hover,
.brand:focus {
  color: #06af8e;
}

.un-authenticated-left-hand {
  display: none;
}

#bg {
  display: none;
}

@media (min-width: 1023px) {
  #bg {
    display: block;
    position: fixed;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    z-index: -1;
  }
  #bg img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    min-width: 50%;
    min-height: 50%;
    z-index: -2;
  }
  #bg:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: inline-block;
    background: linear-gradient(
      to right,
      rgba(8, 7, 8, 0.7) 0%,
      rgba(8, 7, 8, 0.7) 0%,
      rgba(246, 246, 246, 0.85) 55%,
      rgba(255, 255, 255, 0.9) 60%
    );
  }
  .un-authenticated-left-hand {
    display: block;
    float: left;
    margin: 30px;
    color: #ffffff;
  }

  .un-authenticated-left-hand .main-app-name {
    text-align: left;
    margin: 0;
  }

  .un-authenticated-left-hand .main-app-name a {
    color: #ffffff;
  }

  .un-authenticated-left-hand .left-hand-content {
    max-width: 500px;
  }

  .un-authenticated-container {
    margin-right: 40px;
    width: 400px;
    float: right;
  }

  .un-authenticated-container .main-app-name {
    display: none;
  }
}

.home-footer {
  position: static;
  text-align: center;
  width: 100%;
}

@media (min-width: 1023px) {
  .home-footer {
    position: fixed;
    margin: 30px 0 0 30px;
    text-align: left;
  }
  .home-footer a,
  .home-footer a:focus,
  .home-footer a:hover,
  .home-footer a:active {
    color: #fff;
  }
}

.test-something {
  text-align: center;
}
