.Login .forgotten-password-link {
  text-align: center;
  margin-top: 15px;
}

.Login {
  padding: 30px;
}

@media all and (min-width: 480px) {
  .Login {
    padding: 30px 0;
  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
    padding: 0;
    box-shadow: none;
    border-top: none;
    background-color: transparent;
    box-sizing: unset;
  }
}

.Login .signup-link {
  border-top: 1px solid black;
  margin-top: 20px;
  padding-top: 20px;
  text-align: center;
}

.Login .signup-link .btn {
  margin-top: 15px;
}
