.HandicapChange .golfer-col {
  width: 30%;
}
.HandicapChange .handicap-col {
  width: 17.5%;
}
.HandicapChange .adj-col {
  width: 17.5%;
}
.HandicapChange .win-loss-col {
  width: 17.5%;
}
.HandicapChange .new-handicap-col {
  width: 17, 5%;
}

.HandicapChange .fas.fa-arrow-down {
  color: green;
}

.HandicapChange .fas.fa-arrow-up {
  color: red;
}

.HandicapChange .fas.fa-arrow-right {
  color: orange;
}

.HandicapChange .new-handicap {
  min-width: 26px;
  display: block;
  float: left;
}

@media (min-width: 375px) {
  .HandicapChange .new-handicap {
    min-width: 34px;
  }
}

@media (min-width: 520px) {
  .HandicapChange .new-handicap {
    min-width: 40px;
  }
}
