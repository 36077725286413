@media (min-width: 768px) {
  .NewsItem .radio-form-group .radio {
    display: inline-block;
    margin-right: 30px;
  }

  .NewsItem .radio-form-group label {
    display: block;
  }
}
