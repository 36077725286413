.NewsItem .news-item-new {
  width: 30px;
}

.NewsItem .table {
  margin-bottom: 0px;
}

.NewsItem .table > tbody > tr > td {
  border-top: none;
  padding: 4px;
}

.NewsItem hr {
  margin-top: 10px;
  margin-bottom: 10px;
}
