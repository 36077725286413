.ScoresByRound .round-col {
  width: 50%;
}
.ScoresByRound .score-col {
  width: 50%;
}
.ScoresByRound .total {
  font-weight: bold;
}
.ScoresByRound {
  margin-top: 30px;
}

.ScoresByRound .low-round {
  text-decoration: line-through;
  background-color: #f9eded;
}

.ScoresByRound .fa-trophy {
  margin-left: 5px;
}
