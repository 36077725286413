.News {
  margin-bottom: 35px;
}

.News .badge {
  margin-right: 5px;
  font-size: 10px;
  background-color: #d8bf3a;
  border-radius: 0;
}

.News .badge .fas {
  font-size: 10px;
  margin-right: 1px;
}
