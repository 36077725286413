.AggregatedRoundStats .basic-stats td {
  width: 50%;
}

.AggregatedRoundStats .extra-stats td {
  width: 33.3%;
}

.AggregatedRoundStats .circle {
  -webkit-border-radius: 100%;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 100%;
  -moz-background-clip: padding;
  border-radius: 100%;
  background-clip: padding-box;
  text-align: center;
  display: block;
  height: 0;
  width: 100%;
  padding: 50% 0;
  background: lightgrey;
  margin-bottom: 10px;
  font-weight: bold;
  position: relative;
  top: 0;
}

.AggregatedRoundStats .circle > * {
  position: relative;
  font-size: 16px;
  top: -30px;
}

.AggregatedRoundStats .distance-table {
  margin-top: 25px;
}

@media (max-width: 400px) {
  .AggregatedRoundStats .golf-balls {
    display: none;
  }
  .AggregatedRoundStats .distance-table {
    margin-top: 0px;
  }
  .AggregatedRoundStats .distance-avg-val {
    font-weight: normal;
  }
}

@media (min-width: 400px) and (max-width: 500px) {
  .AggregatedRoundStats .circle > * {
    top: -25px;
    font-size: 13px;
  }
}

@media (min-width: 500px) and (max-width: 1499px) {
  .AggregatedRoundStats .golf-balls {
    display: none;
  }

  .AggregatedRoundStats .distance-table {
    margin-top: 0px;
  }
}

@media (min-width: 1500px) {
  .AggregatedRoundStats .circle > * {
    font-size: 20px;
    top: -40px;
  }
}

.AggregatedRoundStats .col {
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.AggregatedRoundStats .circle:after {
  content: "";
  display: block;
  position: absolute;
  left: 25%;
  bottom: 12px;
  width: 50%;
  max-width: 210px;
  height: 8px;
  background: transparent;
  border-radius: 35px/5px;
  box-shadow: 0 50px 40px rgba(0, 0, 0, 0.5);
}

.AggregatedRoundStats .circle:before {
  content: "";
  position: absolute;
  background: radial-gradient(
    circle at 50% 120%,
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0) 70%
  );
  border-radius: 50%;
  bottom: 2.5%;
  left: 5%;
  opacity: 0.6;
  height: 100%;
  width: 90%;
  filter: blur(5px);
  z-index: 2;
}

.AggregatedRoundStats .circle--a {
  background: #fcb400 no-repeat;
  background-size: 100%;
  color: #fff;
}
.AggregatedRoundStats .circle--b {
  background: #06af8e no-repeat;
  background-size: 100%;
  color: #fff;
}
.AggregatedRoundStats .circle--c {
  background: #ef476f no-repeat;
  background-size: 100%;
  color: #fff;
}

.AggregatedRoundStats .circle-label {
  width: 100%;
  text-align: center;
  font-weight: bold;
  margin-bottom: 5px;
  text-decoration: underline;
}

.AggregatedRoundStats .hole-average-col {
  padding: 10px;
  margin: 0 0 30px 0;
}

@media (max-width: 570px) {
  .AggregatedRoundStats .hole-average-col {
    padding: 10px;
  }
}

.AggregatedRoundStats .less-than-avg,
.AggregatedRoundStats .distance-range-low {
  color: red;
}

.AggregatedRoundStats .just-avg,
.AggregatedRoundStats .distance-range-medium {
  color: #cc7a00;
}

.AggregatedRoundStats .more-than-avg,
.AggregatedRoundStats .distance-range-high {
  color: green;
}

.AggregatedRoundStats .fas {
  margin-right: 5px;
  color: #06af8e;
}

.AggregatedRoundStats .fas-thumbtack {
  margin-right: 10px;
}

.AggregatedRoundStats .fas-long-arrow-alt-right {
  margin-right: 7px;
}

.AggregatedRoundStats .distance-avg-desc {
  display: block;
  margin-bottom: 10px;
}

.AggregatedRoundStats .distance-avg-heading {
  display: block;
  margin-bottom: 10px;
}
