.TeamScoresByRound .round-col {
  width: 10%;
}
.TeamScoresByRound .team-col {
  width: 45%;
}
.TeamScoresByRound .total {
  font-weight: bold;
}
.TeamScoresByRound {
  margin-top: 30px;
}

.TeamScoresByRound .fa-trophy {
  margin-right: 5px;
}
