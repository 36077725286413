body {
  margin: 0;
  padding: 0;
  color: #333;
  overflow-x: hidden;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}

p {
  line-height: 1.75;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

h1,
h2,
h3 {
  margin-top: 30px;
  margin-bottom: 20px;
}

section {
  padding: 30px 0;
}

section h2.section-heading {
  font-size: 40px;
  margin-top: 0;
  margin-bottom: 15px;
}

section h3.section-subheading {
  font-size: 16px;
  font-weight: 400;
  font-style: italic;
  margin-bottom: 75px;
  text-transform: none;
  font-family: "Droid Serif", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.btn:not(.btn-link) {
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
}

img::selection {
  background: transparent;
}

img::-moz-selection {
  background: transparent;
}

form .error-message {
  display: none;
}

form .has-error .error-message {
  margin-top: 7px;
  display: block;
}

select.form-control {
  height: 44px;
}

form {
  margin: 0 auto 40px auto;
  /* max-width: 320px; */
}

.form-group-lg .form-control,
.btn {
  border-radius: 4px;
}

.checkbox,
.radio {
  margin-top: 15px;
  margin-bottom: 15px;
}

.page-content form {
  padding: 20px 5% 50px;
  background-color: #fff;
  border-top: 4px solid #06af8e;
  box-shadow: 0 0 40px 6px rgba(0, 0, 0, 0.05);
  max-width: 840px;
  margin: 0 auto 0;
  box-sizing: border-box;
}
.page-content .breadcrumb {
  max-width: 840px;
  margin: 0 auto;
  border-radius: 0;
}
.page-content .nav.nav-pills {
  max-width: 840px;
  margin: 0 auto;
}

.list-group-item:hover,
.list-group-item:focus {
  outline: none;
}

.has-feedback .form-control-feedback.select-feedback {
  top: 29px;
  right: 10px;
}

hr {
  margin-top: 30px;
  margin-bottom: 30px;
}

table .has-feedback .form-control {
  padding: 10px 12px;
}

a,
button.btn-link {
  color: #06af8e;
}

a:focus,
a:hover,
button.btn-link:focus,
button.btn-link:hover {
  color: #1ba26b;
}

.nav-side-menu li > a.active,
.nav-side-menu li > a.active:hover,
.nav-side-menu li > a.active:focus {
  background-color: #06af8e;
}

.btn-primary {
  background-color: #06af8e;
  border-color: #06af8e;
}

.btn-primary:hover {
  background-color: #1ba26b;
  border-color: #1ba26b;
}

.btn-primary.focus,
.btn-primary:focus {
  background-color: #1ba26b;
  border-color: #1ba26b;
}

.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary:active.focus,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover {
  background-color: #1ba26b;
  border-color: #1ba26b;
}

.nav-pills > li.active > a,
.nav-pills > li.active > a:focus,
.nav-pills > li.active > a:hover {
  background-color: #06af8e;
}

.dropdown-menu > .active > a,
.dropdown-menu > .active > a:focus,
.dropdown-menu > .active > a:hover {
  background-color: #06af8e;
}

.btn-primary.disabled.focus,
.btn-primary.disabled:focus,
.btn-primary.disabled:hover,
.btn-primary[disabled].focus,
.btn-primary[disabled]:focus,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary.focus,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary:hover {
  background-color: #06af8e;
  border-color: #06af8e;
}

.fas.platinum,
.fas.platinum {
  color: #06af8e;
}

.fas.gold,
.fas.gold,
.fas.gold,
.fas.gold {
  color: #d8bf3a;
}
.fas.silver,
.fas.silver,
.fas.silver {
  color: #c0c0c0;
}
.fas.bronze,
.fas.bronze,
.fas.bronze {
  color: #c37206;
}
.fas.pink,
.fas.pink,
.fas.pink {
  color: #e04fbc;
}
.fas.standard {
  color: #4267b2;
}

.inline-loading {
  margin: 42px 0;
  color: #06af8e;
}
