.Competition .add-team {
  margin: 25px 0;
}

.Competition .golfer-selected {
  margin-top: 10px;
}

.Competition .confirm-team {
  margin-top: 40px !important;
}

.Competition .round-complete {
  margin-top: 10px;
  display: block;
}

.Competition .finalise-note {
  margin-top: 40px;
  text-align: center;
}
