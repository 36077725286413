.LeaderBoard .place-col {
  width: 10%;
}
.LeaderBoard .golfer-col {
  width: 60%;
}
.LeaderBoard .points-col {
  width: 30%;
}

.LeaderBoard .fa-trophy {
  margin-right: 5px;
}
