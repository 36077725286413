.GolferListDetail.handicaps-table .handicap-col {
  width: 15%;
  text-align: center;
}

.GolferListDetail.handicaps-table .name-col {
  width: 40%;
}

.GolferListDetail.handicaps-table .status-col {
  width: 15%;
  text-align: center;
}

.GolferListDetail.handicaps-table .category-col {
  width: 15%;
  text-align: center;
}

.GolferListDetail.handicaps-table .buffer-col {
  width: 15%;
  text-align: center;
}

.GolferListDetail.handicap-rules-link {
  margin-bottom: 15px;
}

.GolferListDetail.handicap-rules-text {
  margin-bottom: 10px;
}
