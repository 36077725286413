.AnnualGolferHandicapChange .round-col {
  width: 35%;
}
.AnnualGolferHandicapChange .score-col {
  width: 13%;
}
.AnnualGolferHandicapChange .handicap-col {
  width: 13%;
}
.AnnualGolferHandicapChange .adj-col {
  width: 13%;
}
.AnnualGolferHandicapChange .win-loss-col {
  width: 13%;
}
.AnnualGolferHandicapChange .new-handicap-col {
  width: 13%;
}

.AnnualGolferHandicapChange .round-date {
  margin-bottom: 5px;
}

.AnnualGolferHandicapChange .fas.fa-arrow-down {
  color: green;
}

.AnnualGolferHandicapChange .fas.fa-arrow-up {
  color: red;
}

.AnnualGolferHandicapChange .fas.fa-arrow-right {
  color: orange;
}

.AnnualGolferHandicapChange .new-handicap {
  min-width: 26px;
  display: block;
  float: left;
}

.AnnualGolferHandicapChange .manual-adjustment {
  color: red;
}

@media (min-width: 370px) {
  .AnnualGolferHandicapChange .new-handicap {
    min-width: 32px;
  }
}

@media (min-width: 520px) {
  .AnnualGolferHandicapChange .new-handicap {
    min-width: 40px;
  }
}
