.EntityList .cap-entity {
  text-transform: capitalize;
}

.EntityList .entity-disabled {
  cursor: auto;
}

.EntityList a.entity-disabled.list-group-item:focus,
.EntityList a.entity-disabled.list-group-item:hover {
  background-color: #fff;
}
