.AggregatedCompetitionStats .basic-stats td {
  width: 50%;
}

.AggregatedCompetitionStats .extra-stats td {
  width: 33.3%;
}

.AggregatedCompetitionStats .fa-trophy {
  margin-right: 5px;
}
.AggregatedCompetitionStats .fa-list-ol {
  margin-right: 5px;
  color: #06af8e;
}
