.RoundPie {
  margin-bottom: 30px;
}

.RoundPie .chart-title {
  width: 100%;
  text-align: center;
}

@media (max-width: 440px) {
  .RoundPie .recharts-legend-wrapper {
    display: none;
  }
}
