.SWUpdate {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #06af8e;
  z-index: 10;
  color: #fff;
  text-align: center;
  padding: 10px 0;
  vertical-align: middle;
  font-weight: bold;
}
