.Handicap .no-details-to-show {
  margin-top: 40px;
  clear: both;
}

.Handicap .left-section,
.Handicap .right-section {
  padding: 0 0 30px 0;
}

.Handicap .HandicapChart {
  margin: 0;
}

.Handicap .fas {
  margin-right: 5px;
  color: #06af8e;
}
