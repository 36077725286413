.AnnualStats .view-summary {
  display: block;
  margin-top: 10px;
}

.page-content .nav.nav-pills.AnnualStatsSelection {
  max-width: none;
  margin-bottom: 20px;
}

.AnnualStats .table-scroll {
  position: relative;
  width: 100%;
  z-index: 1;
  margin: auto;
  overflow: auto;
  min-height: 100px;
}
.AnnualStats .table-scroll table {
  width: 100%;
  min-width: 2970px;
  margin: auto;
  border-collapse: separate;
  border-spacing: 0;
}
.AnnualStats .table-scroll th,
.AnnualStats .table-scroll td {
  vertical-align: top;
}
.AnnualStats .table-scroll thead th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #fff;
}
/* safari and ios need the tfoot itself to be position:sticky also */
.AnnualStats .table-scroll tfoot,
.AnnualStats .table-scroll tfoot th,
.AnnualStats .table-scroll tfoot td {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  z-index: 4;
}

.AnnualStats .table-scroll th:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 2;
}
.AnnualStats .table-scroll thead th:first-child,
.AnnualStats .table-scroll tfoot th:first-child {
  z-index: 5;
}

.AnnualStats .table-scroll > table > tbody > tr > th:nth-of-type(even) {
  background-color: #fff;
}

.AnnualStats .table-scroll > table > tbody > tr > th:nth-of-type(odd) {
  background-color: #f9f9f9;
}

.AnnualStats .table-scroll .num,
.AnnualStats .table-scroll .col {
  width: 135px;
}

@media (min-width: 570px) {
  .AnnualStats .table-scroll .col-wide {
    width: 250px;
  }
  .AnnualStats .table-scroll table {
    min-width: 3085px;
  }
}

.AnnualStats .table-scroll .num {
  text-align: right;
}

.AnnualStats .less-than-avg,
.AnnualStats .range-low {
  color: red;
}

.AnnualStats .just-avg,
.AnnualStats .range-medium {
  color: #cc7a00;
}

.AnnualStats .more-than-avg,
.AnnualStats .range-high {
  color: green;
}

.AnnualStats .annual-badges {
  margin-top: 10px;
}

.AnnualStats .annual-badges h4 {
  margin-top: 20px;
}
